<script setup>
import { ref, reactive, onMounted } from 'vue';
import { getDataObjectById, getOrCreateDataObject } from 'o365-dataobject';
import { confirm } from 'o365-vue-services';
import { procGetMatrix, modalInitialMatrix, modalFinalMatrix } from 'sja.vue.libraries.modules.utilities.js'

const props = defineProps({
    hazardRow: Object,
    matrixType: String,
});

let maxPValue = ref(0);
let maxCValue = ref(0);
let matrix_cells = reactive([]);
let cellInProgress = ref(0)

let dsSJAMatrix = getOrCreateDataObject({
    id: `dsSJAMatrix`,
    uniqueTable: 'atbv_SJA_Matrix',
    maxRecords: -1,
    viewName: 'aviw_SJA_Matrix',
    allowUpdate: false,
    allowInsert: false,
    allowDelete: false,
    disableLayouts: true,
    fields: [
        { name: 'PrimKey', },
        { name: 'ID', },
        { name: 'Color', },
        { name: 'ProbabilityValue', },
        { name: 'ProbabilityName', },
        { name: 'ProbabilityValueAndName', },
        { name: 'ProbabilityDescription', },
        { name: 'ConsequenceValue', },
        { name: 'ConsequenceName', },
        { name: 'ConsequenceValueAndName', },
        { name: 'ConsequenceDescription', },
        { name: 'SymbolPrimKey', },
        { name: 'SymbolFileName', },
        { name: 'SymbolIcon', },
        { name: 'BIcon', },
        { name: 'Consequence_ID', },
        { name: 'Probability_ID', }
    ]
});

async function populateMatrixArraysAndDS() {
    await dsSJAMatrix.load().then(() => buildMatrix());
}

const buildMatrix = () => {
    maxPValue.value = getMaxXValue();
    maxCValue.value = getMaxCValue();
    for (let p = maxPValue.value; p >= 1; p--) {
        const row = [];
        for (let c = 1; c <= maxCValue.value; c++) {
            const item = dsSJAMatrix.data.find(
                (matrixItem) => matrixItem.ProbabilityValue === p && matrixItem.ConsequenceValue === c
            );
            row.push(item ? { ...item } : null);
        }
        matrix_cells.push(row);
    }
};

const getMaxXValue = () => {
    return Math.max(...dsSJAMatrix.data.map((item) => item.ProbabilityValue));
};

const getMaxCValue = () => {
    return Math.max(...dsSJAMatrix.data.map((item) => item.ConsequenceValue));
};

let dsHazardsModalInitial = null
let dsHazardsModalFinal = null

if (props.matrixType !== 'Setup'){
dsHazardsModalInitial = getDataObjectById(`dsTasksHazardsModalInitial`)
dsHazardsModalFinal = getDataObjectById(`dsTasksHazardsModalFinal`)
}

async function chooseMatrixValue(cell) {
    let ds_TasksHazards;
    let result;
    if (cellInProgress.value == 0) {
        cellInProgress.value = 1,
            await confirm({
                title: $t("Set Risk assessment"),
                message: $t(`Would you like to set the`) + ' ' + props.matrixType + ' ' + $t(`risk assessment as`) + '\n' + '\n' + $t('Probability') + ': ' + cell._item.ProbabilityValueAndName + '\n' + cell._item.ProbabilityDescription + '\n' + '\n' + $t('Consequence') + ': ' + cell._item.ConsequenceValueAndName + '\n' + cell._item.ConsequenceDescription,
                btnTextOk: $t("Yes"),
                btnTextCancel: $t("No"),
            }).then(async () => {
                try {
                    result = await procGetMatrix.execute({
                        ProbabilityValue: cell._item.ProbabilityValue,
                        ConsequenceValue: cell._item.ConsequenceValue,
                        Probability_ID: cell._item.Probability_ID,
                        Consequence_ID: cell._item.Consequence_ID
                    });
                    if (props.matrixType === 'Initial') {
                        modalInitialMatrix.value.hide()
                        dsHazardsModalInitial.current.Matrix_ID = result.Table[0].ID
                        ds_TasksHazards = getDataObjectById(`dsTasksHazards_${dsHazardsModalInitial.current.SJA_ID}`);
                        await dsHazardsModalInitial.save().then(() => ds_TasksHazards.load())
                        dsHazardsModalInitial.load()
                    }
                    if (props.matrixType === 'Final') {
                        modalFinalMatrix.value.hide()
                        dsHazardsModalFinal.current.FinalMatrix_ID = result.Table[0].ID
                        ds_TasksHazards = getDataObjectById(`dsTasksHazards_${dsHazardsModalFinal.current.SJA_ID}`);
                        await dsHazardsModalFinal.save().then(() => ds_TasksHazards.load())
                        dsHazardsModalFinal.load()
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    
                }
            }).catch(() => {
            });
            cellInProgress.value = 0;
    }

}

onMounted(() => {
    populateMatrixArraysAndDS();
});

</script>

<template>
    <div>
        <table class="table-matrix table-matrix-sm">
            <tbody>
                <tr>
                    <td></td>
                    <td v-for="(cell, cellIndex) in matrix_cells[0]" :title="cell._item.ConsequenceName"><img v-if="cell._item.SymbolFileName" class="consequence-style"
                            :src="'/api/file/view/atbl_SJA_Symbols/' + cell._item.SymbolPrimKey"><i
                            v-if="!cell._item.SymbolFileName && cell._item.BIcon" class="fs-5 bi" :class="cell._item.BIcon"></i></td>
                    <td :rowspan="maxPValue + 1"></td>
                </tr>
                <tr>
                    <td></td>
                    <td v-for="(cell, cellIndex) in matrix_cells[0]">{{ cell._item.ConsequenceValue }}</td>
                    <td :rowspan="maxPValue + 1">
                        <div class="pLabel">
                            {{ $t('Probability') }}
                        </div>
                    </td>
                </tr>
                <tr v-for="(row, rowIndex) in matrix_cells" :key="rowIndex">
                    <td class="matrix-probability-text">
                        <span class="fs-6">{{ row[0]._item.ProbabilityValue }}</span>
                    </td>
                    <td class="cell" v-for="(cell, cellIndex) in row" :key="cellIndex"
                        :style="{ 'background-color': cell._item.Color }"
                        :title="$t('Probability') + ': ' + cell._item.ProbabilityValueAndName + '\n' + $t('Consequence') + ': ' + cell._item.ConsequenceValueAndName"
                        style="cursor: help;" @click="props.matrixType !== 'Setup' ? chooseMatrixValue(cell) : null">
                        <i v-if="(props.matrixType === 'Initial' && cell._item.ID === props.hazardRow[0]?.Matrix_ID)"
                            class="fw-bold fs-4 text-black bi bi-x-lg"></i>
                        <i v-if="props.matrixType === 'Final' && cell._item.ID === props.hazardRow[0]?.FinalMatrix_ID"
                            class="fw-bold fs-4 text-black bi bi-x-lg"></i>
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td class="cLabel text-center" :colspan="maxCValue">{{ $t('Consequence') }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<style scoped>
.consequence-style {
    max-width: 25px;
    max-height: 25px;
}

.table-matrix {
    text-align: center;
}

.table-matrix td {
    border: 0.5px solid lightgray;
}

.table-matrix td.matrix-probability-text {
    font-size: 0.8em;
    border: none !important;
    text-align: left;
    padding-left: 6px;
    padding-right: 6px;
    white-space: nowrap;
}

.table-matrix td.matrix-consequence-text {
    vertical-align: top;
    font-size: 0.8em;
    border: none !important;
}

.table-matrix tr td:first-child {
    text-align: left;
}

.table-matrix tr td:not(.cell) {
    border: none !important;
}

.table-matrix td.cell:hover {
    -webkit-box-shadow: 2px 4px 22px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 4px 22px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 4px 22px 2px rgba(0, 0, 0, 0.75);
}

.cLabel {
    height: 26.5px;
    text-align: left;
    font-size: 1rem;
}

.pLabel {
    display: inline-table;
    writing-mode: tb-rl;
    white-space: pre;
    font-size: 1rem;
    padding-left: 6px;
}

/* Matrix - extra small */
.table-matrix-xs td.cell {
    width: 25px;
    height: 25px;
    border: 0.5px solid lightgray;
    font-weight: bold;
    max-height: 25px;
    min-width: 25px;
}

/* Matrix - small*/
.table-matrix-sm td.cell {
    width: 50px;
    height: 50px;
    border: 0.5px solid lightgray;
    font-weight: bold;
    max-height: 50px;
    min-width: 50px;
}

/* Matrix - medium */
.table-matrix-md td.cell {
    width: 70px;
    height: 70px;
    border: 0.5px solid lightgray;
    font-weight: bold;
    max-height: 70px;
    min-width: 70px;
    overflow: auto;
}

/* Matrix - large */
.table-matrix-lg td.cell {
    width: 100px;
    height: 100px;
    border: 0.5px solid lightgray;
    font-weight: bold;
    max-height: 100px;
    min-width: 100px;
}
</style>